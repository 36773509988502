<template>
  <v-card class="my-1">
    <v-card-title>
      <h5>Concluídas</h5>
    </v-card-title>
    <v-card-text>
      <v-radio-group 
        v-model="visaoGraficoQuantidades" 
        row
        class="mb-0 ml-2"
      >
        <v-radio
          label="Valores"
          value="valor"
        />
        <v-radio
          label="Porcentagem"
          value="porcentagem"
        />
      </v-radio-group>
      <highcharts 
        :options=chartQuantidadesOptions 
        :deepCopyOnUpdate="false" 
      />

      <highcharts 
        :options=chartPrazoMedioOptions 
        :deepCopyOnUpdate="false" 
      />

      <highcharts 
        :options=chartCompensacoesOptions 
        :deepCopyOnUpdate="false" 
      />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "DashboardServicosVisaoAneelCardConcluidas",
  props: {
    dados: {
      type: Object,
      required: true,
    },
    competencias: {
      type: Array,
      required: true
    },
    prazoReguladoServico: {
      type: Number,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    visaoGraficoQuantidades: "valor",
    chartQuantidadesOptions: {},
    chartPrazoMedioOptions: {},
    chartCompensacoesOptions: {},
  }),
  computed: {
    categories() {
      // Solicitação do Tiburcio: deve haver um range de pelo menos 12 meses na visualização do dashboard
      if (this.competencias.length < 12) {
        const startDate = dayjs(this.competencias[0], 'MM/YYYY').format("YYYY-MM"); 
        const endDate   = dayjs(this.competencias[0], 'MM/YYYY').add(11, 'month').format("YYYY-MM");
  
        return generateMonthlyDateRangeArray(startDate, endDate, 'YYYY-MM');
      }

      return this.competencias;
    },
  },
  mounted() {
    this.setChartsOptions();
  },
  methods: {
    setChartsOptions() {
      this.setChartQuantidadesOptions();
      this.setChartPrazoMedioOptions(); 
      this.setChartCompensacoesOptions();
    },
    getColumnChartDefaultOptions() {
      return {
        chart: {
          type: 'column',
          zoomType: 'xy',
          height: 200,
        },
        colors: [
          "#550899",
          "#FF5252"
        ],
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: "Competência" 
          }
        },
        yAxis: {
          title: { text: null },
          labels: { 
            format: '{value}'
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          }
        },
        tooltip: { 
          shared: true,
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [],
        exporting: this.exportingOptions,

      };
    },
    setChartQuantidadesOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.yAxis.labels.format = this.visaoGraficoQuantidades === 'porcentagem' ? '{value}%' : '{value}';
      options.plotOptions.column.stacking = this.visaoGraficoQuantidades === 'porcentagem' ? 'percent' : 'normal';
      options.series = [
        {
            name: "Quant. prazo",
            animation: false,
            data: this.categories
              .map(category => {
                return category in this.dados 
                  ? { x: this.categories.indexOf(category), y: Number(this.dados[category].qtd_prazo) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              // .filter(val => val !== null && val.y !== null && val.y > 0)
          },
          {
            name: "Quant. fora prazo (SERV_008)",
            animation: false,
            data: this.categories
              .map(category => {
                return category in this.dados 
                  ? { x: this.categories.indexOf(category), y: Number(this.dados[category].qtd_fora_prazo) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              // .filter(val => val !== null && val.y !== null && val.y > 0)
          }
      ];

      this.chartQuantidadesOptions = options;
    },
    setChartPrazoMedioOptions() {
      this.chartPrazoMedioOptions = {
        chart: {
          type: 'spline',
          zoomType: 'x',
          height: 200,
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: "Competência"
          }
        },
        yAxis: {
          title: { text: null }
        },
        tooltip: { 
          shared: true,
          valueDecimals: 2
        },
        plotOptions: {
          series: {
            marker: { 
              // enabledThreshold: 12,
              radius: 2,
            },
            animation: false,
            connectNulls: true,
          }
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [
          {
            name: "PM Fora Prazo (SERV_009)",
            animation: false,
            color: "#FF5252",
            data: this.categories
              .map(category => {
                return category in this.dados 
                  ? { x: this.categories.indexOf(category), y: Number(this.dados[category].pm_fora_prazo) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              // .filter(val => val !== null && val.y !== null && val.y > 0)
          },
          {
            name: "PM Total (SERV_007)",
            animation: false,
            color: "#550899",
            data: this.categories
              .map(category => {
                return category in this.dados 
                  ? { x: this.categories.indexOf(category), y: Number(this.dados[category].pm_total) } 
                  : { x: this.categories.indexOf(category), y: null }
              })
              // .filter(val => val !== null && val.y !== null && val.y > 0)
          },
          {
            name: "Prazo Regulado",
            animation: false,
            color: "#4CAF50",
            data: this.categories
              .map(category => {
                return { x: this.categories.indexOf(category), y: this.prazoReguladoServico };
              })
          }
        ],
        exporting: this.exportingOptions,
      };
    },
    setChartCompensacoesOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = {
        name: "Compensações",
        color: "#550899",
        animation: false,
        data: this.categories
          .map(category => {
            return category in this.dados 
              ? { x: this.categories.indexOf(category), y: Number(this.dados[category].compensacoes) } 
              : { x: this.categories.indexOf(category), y: null }
          })
          // .filter(val => val !== null && val.y !== null && val.y > 0)
      };

      this.chartCompensacoesOptions = options;
    },
  },
  watch: {
    dados() {
      this.setChartsOptions();
    },
    visaoGraficoQuantidades() {
      this.setChartQuantidadesOptions();
    },
  },
}
</script>

<style></style>